.errorMessage {
  font-size: 0.875rem;
  color: red;
}
.disbursement_container {
  padding: 1rem;
  height: 70vh;
  scrollbar-color: auto;
  overflow-y: scroll;
  position: relative;

  .view_btn_container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .disbursement_summary_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .view_equity_contribution {
      background-color: #713fff;
      color: #fff;
      border-color: #713fff;

      &:hover {
        background-color: transparent;
        color: #713fff;
        border-color: #713fff;
      }
    }

    .view_offer_btn {
      &:hover {
        color: #713fff;
        border-color: #713fff;
      }
    }
  }

  .awaiting_customer_loan_offer_acceptance {
    position: absolute;
    left: 400px;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #fef4e5;
    border-radius: 10px;
    padding: 8px;
    box-shadow: 0 8px 24px 0 rgba(149, 157, 165, 0.2);

    .awaiting_customer_loan_offer_text {
      color: #c37e0b;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
}

.new_loan_offer_trigger_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fef4e5;
  border-radius: 10px;
  padding: 8px;
  width: 50%;
  box-shadow: 0 8px 24px 0 rgba(149, 157, 165, 0.2);

  .close_btn {
    cursor: pointer;
  }

  .new_offer_trigger_text {
    color: #c37e0b;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-left: 20px;
  }

  .new_loan_offer_trigger_action_btn {
    padding: 10px;
    background-color: #c37e0b;
    border: none;
    border-radius: 24px;
    color: #fff;
  }
}

.new_loan_offer_success_msg_container {
  box-shadow: 0 8px 24px 0 rgba(149, 157, 165, 0.2);
  background-color: #eaf9ea;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;

  .new_offer_success_text {
    color: #25ad25;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24px */
  }
}

.commentLabel {
  width: 100%;
}

.commentErrorState {
  color: #ed3b3b;
}

.commentInputState {
  border-color: #ed3b3b;
  outline-color: #ed3b3b;
}
